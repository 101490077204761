/* src/index.css or src/App.css */

/* Import Roboto Condensed font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

/* Apply Tailwind's utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-200;  
}